export default {
    visitorPins: 'Visitor PINs',
    accessSettings: 'Pin Access Settings',
    singleUsePass: 'Single Use Pass',
    multiUsePass: 'Multi Use Pass',
    singlePassDesc:
        'Single Use Pass is to be used for short-lived temporary access. It expires 5 min after first use. It can be used immediately after creation if activation date/time is not provided.',
    multiplePassDesc:
        'Multi Use Pass is to be used for long term access. Ideal for family, friends and people you know well. It can be used immediately after creation if activation date/time is not provided. If deactivation date is not provided, it is active indefinitely.',
    passInfo: 'Pass Information',
    optionalItems: 'Optional Items',
    addPass: 'Add Visitor Pass',
    passType: 'Pass Type',
    site: 'Site',
    selectSite: 'Select site',
    passName: 'Pass Name',
    passDescription: 'Pass Description',
    description: 'Description',
    accessPoints: 'Access Points',
    selectAccessPoints: 'Select Access Points',
    visitorName: 'Visitor Name',
    visitorEmail: 'Visitor Email',
    sendEmail: 'Send this pass to visitor by email',
    activationDate: 'Activation date',
    deactivationDate: 'Deactivation date',
    time: 'Time',
    selectTime: 'Select time',
    pin: 'Pin',
    name: 'Name',
    type: 'Type',
    accessTime: 'Access Time',
    status: 'Status',
    lastUsedAt: 'Last Used at',
    visitorPassSettings: 'Visitor Pass Settings',
    allowAll: 'Allow All',
    restrictedByUserGroup: 'Restrict By Access Group',
    allUsersDesc: 'Allow all users from the Account to create both single-use and multi-use PIN codes for visitors.',
    singleUse: 'Single Use Pin Access groups',
    singleUseLabel: 'Single-Use PIN Codes',
    singleUseDesc:
        'Select the Access Group(s) that can create single-use PIN codes. Users will be able to create single-use PIN codes that grant access to all doors they also have access to.',
    multiUseLabel: 'Multi-Use PIN Codes',
    multiUseDesc:
        'Select the Access Group(s) that can create multi-use PIN codes. Users will be able to create multi-use PIN codes that grant access to all doors they also have access to.',
    settingsDesc: 'Select which users or access groups have the ability to create and share single-use or multi-use PIN codes.',
    adminsHasAccess: 'Admins have default access to create both single-use and multi-use PIN codes.',
    notes: 'Notes',
    instructionsForVisitor: 'Instructions for the visitor, if any',
    pinUsageAlert: 'Get Pin Usage Alerts',
    pinUsageAlertHelp: 'You will get an email and push notification when the visitor pin is used',
    invalidEmail: 'Invalid Email',
    multipleSitesDesc:
        "If you need pass for Sites from different time zones, it's recommended that you create separate visitor pass for each site",
    addSchedule: 'Add Schedule',
    grantAccess: 'Grant Access',
    selectAtleastOneDesc: 'For each site, you need to select at least one of Access Points, Floors or Units to grant access to Visitor',
    floors: 'Floors',
    selectFloors: 'Select Floors',
    selectFloorHelpText: 'List of Floors you have access to. You can restrict access to selected floors for visitors',
    selectUnits: 'Select Units',
    allUsers: 'All Users',
    showMyPasses: 'Show My Passes',
    visitorPin: 'Visitor Pin',
    autoGenerate: 'Auto-generate',
    createOwnPin: 'Create Your Own Pin',
    passDetails: 'Pass Details',
    passNameExpl: 'Enter own name or select from the suggestions',
    pressToCopyPin: 'Press to copy PIN',
    ok: 'OK',
    copyPin: 'Copy PIN',
    pinCreateSuccess: {
        p1: 'Pin',
        p2: 'is created successfully.',
    },
};
